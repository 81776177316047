import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../src/types';

const IcoCheck16 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.189 3.271a.764.764 0 1 1 1.08 1.08l-8.4 8.4a.763.763 0 0 1-1.08 0L1.735 9.697a.764.764 0 1 1 1.08-1.08l2.514 2.514 7.86-7.86Z"
        fill={theme.color[color]}
      />
      <path
        d="m13.714 3.04-.008-.25.008.25Zm-.525.231.177.177.002-.002-.18-.175Zm.96-.11.135-.21-.135.21Zm.29.345.23-.097-.23.097Zm-.17.845-.175-.179-.002.003.177.176Zm-8.4 8.4.177.177-.177-.177Zm-1.08 0-.177.177.177-.177ZM1.735 9.697l.177-.177-.004-.004-.173.18Zm-.172-.248.23-.1-.23.1ZM1.5 9.153l-.25.003.25-.003Zm.057-.296-.232-.095.232.095Zm.167-.251.176.176-.176-.176Zm.251-.167.095.231-.095-.231Zm.592.006-.1.23.1-.23Zm.248.172-.18.173.003.004.177-.177Zm2.514 2.514-.177.177.177.177.177-.177-.177-.177Zm8.377-8.34a1.014 1.014 0 0 0-.697.306l.36.349a.514.514 0 0 1 .352-.156l-.015-.5Zm.578.16a1.014 1.014 0 0 0-.578-.16l.015.5a.514.514 0 0 1 .293.08l.27-.42Zm.386.458a1.013 1.013 0 0 0-.386-.458l-.27.42a.514.514 0 0 1 .195.232l.46-.194Zm.06.596c.04-.2.02-.408-.06-.596l-.46.194c.04.096.05.201.03.303l.49.099Zm-.287.526c.146-.143.246-.326.287-.526l-.49-.1a.514.514 0 0 1-.146.267l.349.359Zm-8.397 8.397 8.4-8.4-.354-.353-8.4 8.4.354.353Zm-.717.296c.269 0 .527-.106.717-.296l-.354-.354a.514.514 0 0 1-.363.15v.5Zm-.717-.296c.19.19.448.296.717.296v-.5a.514.514 0 0 1-.363-.15l-.354.354ZM1.558 9.873l3.054 3.055.354-.354L1.912 9.52l-.354.353Zm-.225-.325c.054.124.131.235.229.329l.346-.36a.514.514 0 0 1-.116-.168l-.459.199Zm-.083-.392c.001.135.03.268.083.392l.46-.199a.513.513 0 0 1-.043-.198l-.5.005Zm.075-.394a1.014 1.014 0 0 0-.075.394l.5-.005c0-.068.012-.136.038-.2l-.463-.189Zm.222-.333c-.095.095-.17.209-.222.333l.463.19a.514.514 0 0 1 .112-.17l-.353-.353Zm.334-.222a1.014 1.014 0 0 0-.334.222l.353.353a.514.514 0 0 1 .17-.112l-.19-.463Zm.393-.075a1.014 1.014 0 0 0-.393.075l.189.463a.514.514 0 0 1 .199-.038l.005-.5Zm.392.083a1.014 1.014 0 0 0-.392-.083l-.005.5c.068 0 .136.015.198.042l.199-.459Zm.329.229a1.014 1.014 0 0 0-.33-.229l-.198.46c.063.026.12.066.167.115l.36-.346Zm2.51 2.51L2.992 8.44l-.353.354 2.514 2.514.354-.354Zm7.507-7.86-7.86 7.86.354.354 7.86-7.86-.354-.353Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};

export default IcoCheck16;
