import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../src/types';

const IcoPhone24 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        d="m8.893 4.75.624-.416A.75.75 0 0 0 8.893 4v.75Zm2.071 3.107.53.53a.75.75 0 0 0 .094-.946l-.624.416ZM9.327 9.495l-.53-.53.53.53Zm6.816 3.54.416-.623a.75.75 0 0 0-.947.093l.53.53Zm3.107 2.072H20a.75.75 0 0 0-.334-.624l-.416.624ZM9.128 10.494l.678-.32-.678.32Zm1.836 2.542-.517.543.517-.543Zm2.518 1.796-.344.666.344-.666ZM6.068 5.5h2.825V4H6.068v1.5Zm2.2-.334 2.072 3.107 1.248-.832-2.071-3.107-1.248.832Zm2.166 2.16L8.796 8.965l1.061 1.061 1.638-1.638-1.061-1.06Zm4.628 7.851 1.611-1.611-1.06-1.06L14 14.115l1.061 1.061Zm.665-1.517 3.107 2.071.832-1.248-3.107-2.071-.832 1.248Zm2.773 1.447v2.825H20v-2.825h-1.5ZM8.45 10.815c.313.662.934 1.75 1.997 2.764l1.035-1.086a7.978 7.978 0 0 1-1.676-2.32l-1.356.642Zm1.997 2.764c1.02.972 2.052 1.59 2.69 1.92l.69-1.333a10.343 10.343 0 0 1-2.345-1.673l-1.035 1.085Zm8.053 4.353a.568.568 0 0 1-.568.568V20A2.068 2.068 0 0 0 20 17.932h-1.5Zm-4.499-3.815a.204.204 0 0 1-.104.058.104.104 0 0 1-.07-.01l-.69 1.333c.674.348 1.44.164 1.925-.32l-1.06-1.062Zm3.93 4.383C11.067 18.5 5.5 12.934 5.5 6.068H4C4 13.762 10.238 20 17.932 20v-1.5ZM8.797 8.964c-.46.46-.66 1.187-.346 1.85l1.356-.64a.078.078 0 0 1-.005-.054.196.196 0 0 1 .056-.095l-1.06-1.06ZM6.068 4A2.068 2.068 0 0 0 4 6.068h1.5c0-.314.254-.568.568-.568V4Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};

export default IcoPhone24;
